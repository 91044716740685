<template>
    <div class="platformList">
        <!-- <div class="title">账户管理</div> -->
        <div class="headBox">
            <div>
                <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="back()">返回</el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addPlatform()">开通支付平台</el-button>
            </div>

            <div class="right">
                
            </div>
        </div>
        <table-list
            :ButtonWidth="140"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
              
                <span v-if="scope.colr.prop == 'name'">
                    <span>{{ $route.query.name }}</span>
                </span>
            </template>
            <template #Button="scope">
                
                <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
                <el-button size="mini" type="text" style="color: #f56c6c" @click="del(scope.row)">删除</el-button>
            </template>
        </table-list>

        
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import businessConfigureApi from "../../api/businessConfigureApi";
export default {
    name: "platformList",
    components: { TableList },
    data() {
        return {
            tableData: [],
            colrs: [
                { label: "商户ID", prop: "merchantId"},
                
                { label: "租户名称", prop: "name" },
                { label: "支付平台", prop: "paymentPlatformTypeDesc" },
            ],
            total: 0,
            pagesize: 20,
            pageIndex: 1,
            id: "",
            phoneNumber: "",

            dialogVisible: false,
            userForm: {},
            tenantIdOptions: [],

            // isTestOptions: [],
            // isTest: "",

           
            userId:''
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            businessConfigureApi
                .platformData({
                    tenantId: this.$route.query.tenantId,
                    // pageSize: this.pagesize,
                    // pageNumber: this.pageIndex
                })
                .then(res => {
                    if (res.success) {
                        this.tableData = res.data;
                    }
                });
        },

        addPlatform() { 
            this.$router.push({ name: "/userConfigure/addBusinessPage", query: { tenantId: this.$route.query.tenantId, name: this.$route.query.name} });
        },
        edit(row) {
     
            this.$router.push({ name: "/userConfigure/addBusinessPage", query: { tenantId: this.$route.query.tenantId, name: this.$route.query.name,edit:1,id:row.id} });
          
        },

        check() {
            
        },
        back() {
            this.$router.back();
        },
        del(row) {
            businessConfigureApi
                .platformDel({
                    id: row.id
                })
                .then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: "删除成功"
                        });
                        this.getData();
                    } else {
                        this.$message({
                            type: "warning",
                            message: "删除失败"
                        });
                    }
                });
        },

        handleClose() {},

        currentChange(pageIndex) {
            this.pageIndex = pageIndex;
        },
        handleSizeChange(pagesize) {
            this.pageIndex = 1;
            this.pagesize = pagesize;
        }
    }
};
</script>
<style lang="scss" scoped>
.platformList {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    height: 89vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
            gap: 10px;
            .select {
                display: flex;
                align-items: center;
                .selectTit {
                    background-color: #f5f7fa;
                    color: #909399;
                    vertical-align: middle;
                    display: table-cell;
                    position: relative;
                    border: 1px solid #dcdfe6;
                    border-right: 0;
                    border-radius: 4px 0 0 4px;
                    padding: 0 20px;
                    height: 26px;
                    line-height: 26px;
                    white-space: nowrap;
                }
                .el-select {
                    width: 180px;
                    /deep/.el-input__inner {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
    }
    .box-card {
        // width: 600px;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
    }
    .userInfo_title {
        font-size: 16px;
        color: #222222;
        margin-bottom: 40px;
        font-weight: 700;
    }
    .zhBialog {
        /deep/.el-dialog__header,
        /deep/.el-dialog__body {
            padding: 0;
        }
    }
}
</style>
